import type { JQuery } from "jquery";
import { XF } from "./XF";

XF.Event.extend("menu", {
  click(e: Event) {
    e.preventDefault();
    Object.getPrototypeOf(Object.getPrototypeOf(this)).click.call(this, e);

    // iOS has a known flaw of not repositioning elements when keyboard shown
    if (XF.isIOS()) {
      setTimeout(() => {
        const $buttonBottom = this.$target[0].getBoundingClientRect().bottom;
        const $menuBottom = this.$menu[0].getBoundingClientRect().bottom;

        const $scrollTargetEl =
          $menuBottom >= $buttonBottom ? this.$menu[0] : this.$target[0];

        $scrollTargetEl?.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }, 100);
    }
  },
  getHorizontalPosition(viewport, menuCss) {
    let menuIsRight = false;
    let deltaLeft = 0;

    if (this.menuWidth > viewport.width) {
      // align menu to left viewport edge if menu is wider than viewport
      deltaLeft = this.menuRef.left - viewport.left;
    } else if (
      this.menuRef.left + this.menuRef.width / 2 >
      viewport.width * this.options.directionThreshold
    ) {
      // align menu to right of this.menuRef if this.menuRef center is viewportwidth/directionThreshold of the page width
      deltaLeft = 0 - this.menuWidth + this.menuRef.width;
      menuIsRight = true;
    } else if (this.menuRef.width > this.menuWidth) {
      // align menu with middle of the ref
      deltaLeft = Math.floor((this.menuRef.width - this.menuWidth) / 2);
    }

    // corrections to constrain to viewport, as much as possible, with 5px to spare
    deltaLeft = Math.min(
      deltaLeft,
      viewport.right - this.menuWidth - this.menuRef.left - 5
    );
    deltaLeft = Math.max(deltaLeft, viewport.left - this.menuRef.left + 5);

    // Line up the menu with the right side of the button that triggered it
    const buttonOffset =
      this.menuRef.width - (this.$target.offset().left - this.menuRef.left);

    // final calculation for menu left position
    menuCss.left =
      this.menuRef.left + deltaLeft - buttonOffset + this.$target.outerWidth();

    this.$target.data("menu-h", [
      this.menuRef.left,
      this.menuRef.width,
      deltaLeft,
    ]);

    this.$menu
      .toggleClass("menu--left", !menuIsRight)
      .toggleClass("menu--right", menuIsRight);

    // don't allow the arrow to be moved outside of the menu
    const arrowOffset = Math.min(
      this.arrowRef.left -
        this.menuRef.left +
        this.arrowRef.width / 2 -
        deltaLeft,
      this.menuWidth - 20
    );

    this.$menu.find(".menu-arrow").css({
      top: "",
      left: arrowOffset,
    });

    return menuCss;
  },
});

// Override default XF.PageJump's go func
XF.Element.extend("page-jump", {
  go() {
    let page = parseInt(this.$input.val(), 10);
    if (page < 1) {
      page = 1;
    }

    const baseUrl = this.options.pageUrl;
    const { sentinel } = this.options;
    let url = baseUrl.replace(sentinel, page);

    // eslint-disable-next-line eqeqeq
    if (url == baseUrl) {
      url = baseUrl.replace(encodeURIComponent(sentinel), page);
    }

    // Override Point - Add in current anchor to url if its not there
    const currentAnchor = $(window.location).attr("hash");
    if (currentAnchor && !url.includes("#")) {
      url += currentAnchor;
    }

    XF.redirect(url);
  },
});

XF.Element.extend("tabs", {
  getSelectorFromHash() {
    let selector = "";
    if (window.location.hash.length > 1) {
      const hash = window.location.hash.replace(/[^a-zA-Z0-9_-]/g, "");
      if (hash && hash.length) {
        /* fix issue with #profile-post-[id] or #profile-post-comment-[id] */
        if (hash.match(/^profile-post(-comment)?-\d+$/gi)) {
          return "#profile-posts";
        }

        selector = `#${hash}`;
      }
    }
    return selector;
  },

  onPopState(e) {
    const popStateEvent = e.originalEvent;
    const { state } = popStateEvent;

    if (state && state.id) {
      this.activateTarget(`#${state.id}`, false);
    } else if (state && state.offset) {
      this.activateTab(state.offset);
    }
  },

  activateTab(
    this: {
      $tabs: JQuery;
      $panes: JQuery;
      $target: JQuery;
      lastTab: number | undefined;
      options: { activeClass: string };
    },
    offset: number
  ) {
    const $tab = this.$tabs.eq(offset);
    const $pane = this.$panes.eq(offset);
    const { activeClass } = this.options;

    if (!$tab.length || !$pane.length) {
      console.error(`Selected invalid tab ${offset}`);
      return;
    }

    // deactivate active other tab
    this.$tabs
      .filter(`.${activeClass}`)
      .removeClass(activeClass)
      .attr("aria-selected", "false")
      .trigger("tab:hidden");
    this.$panes
      .filter(`.${activeClass}`)
      .removeClass(activeClass)
      .attr("aria-expanded", "false")
      .trigger("tab:hidden");
    this.$target[0].querySelectorAll(".dropdown-tab").forEach((element) => {
      element.classList.remove(activeClass);
    });

    // activate tab
    $tab
      .addClass(activeClass)
      .attr("aria-selected", "true")
      .trigger("tab:shown");
    $pane
      .addClass(activeClass)
      .attr("aria-expanded", "true")
      .trigger("tab:shown");
    const parentTab = $tab[0]?.dataset?.tabId;
    if (parentTab) {
      $(`#${parentTab}`)
        .addClass(activeClass)
        .attr("aria-expanded", "true")
        .trigger("tab:shown");
    }

    XF.layoutChange();

    if ($pane.data("href")) {
      if ($pane.data("tab-loading")) {
        return;
      }
      $pane.data("tab-loading", true);

      const urlParams = Object.fromEntries(
        new URLSearchParams(window.location.search)
      );

      const isInitialLoad = !this.lastTab;
      const isPaginationRefresh = parseInt(urlParams?.page, 10) > 0;
      this.lastTab = offset;

      XF.ajax("get", $pane.data("href"), urlParams, (data) => {
        $pane.data("href", false);
        if (data.html) {
          const loadTarget = $pane.data("load-target");

          if (loadTarget) {
            XF.setupHtmlInsert(data.html, $pane.find(loadTarget));
          } else {
            XF.setupHtmlInsert(data.html, ($html) => {
              $pane.html($html);
              if (isInitialLoad && isPaginationRefresh) {
                $pane[0]?.firstElementChild?.scrollIntoView();
              }
            });
          }
        }
      }).always(() => {
        $pane.data("tab-loading", false);
      });
    }
  },
});

XF.Event.extend("overlay", {
  show(this: typeof XF.OverlayClick, e: Event) {
    const parentClass = Object.getPrototypeOf(Object.getPrototypeOf(this));
    parentClass.show.call(this, e);
    $(document).trigger("overlay:shown", this.loadUrl);
  },
});
